html {
  font-family: arial;
  font-size: 16px;
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
}

#root {
  height: 100%;
}

code {
  font-family: monospace;
}

::-webkit-scrollbar {
  width: 0px;
}